.navigation{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    height: 55px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    z-index: 88;
}
.navigation::before{
    position: absolute;
    content: "";
    border-bottom: solid 1px #CFCFCF;
    width: 80%;
    /*margin-left: 5%;*/
    bottom: 53px;
}
.navigation li{
    display: inline-block;
    width: 50px;
    height: 40px;
    margin-top: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
}
.navigation li.active{
    background-size: 80%;
}
.navigation li.dic{ background-image: url("../Images/dic.png"); }
.navigation li.dic.active{ background-image: url("../Images/dic-a.png"); }

.navigation li.main{ background-image: url("../Images/cart.png"); }
.navigation li.main.active{ background-image: url("../Images/cart-a.png"); }

.navigation li.history{ background-image: url("../Images/hist.png"); }
.navigation li.history.active{ background-image: url("../Images/his-a.png"); }

.navigation li.settings{ background-image: url("../Images/set.png"); }
.navigation li.settings.active{ background-image: url("../Images/set-a.png"); }