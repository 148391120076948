.style1{
    color: #089CFD;
    color: #6AEAFC;
}

body, html, #root{
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
}
@font-face {
    font-family: 'HelveticaNeue';
    src: url('../Assets/fonts/HelveticaNeueCyr-Thin.ttf');
}
@font-face {
    font-family: 'HelveticaNeueM';
    src: url('../Assets/fonts/HelveticaNeueCyr-Medium.otf');
}
@font-face {
    font-family: 'Sansation';
    src: url('../Assets/fonts/Sansation_Regular.ttf');
}
@font-face {
    font-family: 'Poppins';
    src: url('../Assets/fonts/Poppinsregular.ttf');
}
@font-face {
    font-family: 'Poppins';
    /*src: url('../Assets/fonts/Poppinsregular.ttf');*/
    src: url('../Assets/fonts/Sansation_Light.ttf');
}
#root{
    padding-top: 50px;
    padding-bottom: 55px;
    height: calc(100% - 105px);
}
.loader-container{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #089CFD;
    font-size: 24px;
}
.header{
    /*border: solid 1px #f00;*/
    height: 50px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 6;
    background-color: #fff;
    display: flex;
}

.dicBtn{
    width: 40px;
    position: absolute;
    right: 5px;
    top: 5px;
    height: 40px;
    border-radius: 20px;
    /*border: solid 1px #f00;*/
    display: inline-block;
    z-index: 56;
    background: url("../Images/cross.svg") no-repeat center;
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
}

.header::before{
    position: absolute;
    content: "";
    border-bottom: solid 1px #CFCFCF;
    width: 80%;
    margin-left: 10%;
    top: 48px;
}
.header .logo{
    height: 100%;
    width: 50px;
    margin-left: 15px;
    background-image: url("../Images/mCalcLogo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
}
.header .button{
    position: absolute;
    right: 15px;
    top: 0;
    height: 50px;
    width: 50px;
    background-image: url("../Images/new-order.png");
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center right;
}
.content{
    padding-bottom: 55px !important;
}
.dic-list{
    margin: 0;
    padding: 10px;
}
.dic-list li{
    display: flex;
    border: solid 1px #DADADA;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 4px;
    line-height: 40px;
    padding-left: 10px;
}
.dic-list li:not(:active) {
    /* now keep red background for 1s */
    transition: background-color 100ms step-end;
}

.dic-list li:active {
    background: #4A94D822;
}
.dic-list li .title{
    font-size: 12px;
    color: #3D3D3D;;
    font-family: HelveticaNeue;
    width: calc(100% - 130px);
    text-transform: uppercase;
    line-height: 43px;
    padding-left: 4px;
}
.dic-list li .hist{
    display: inline-flex;
    width: 150px;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    padding-right: 12px;
    padding-top: 9px;
}
.dic-list li .min, .dic-list li .max, .dic-list li .avg, .dic-list li .last{
    vertical-align: top;
    display: inline-block;
    border: none;
    padding: 3px 7px;
    font-size: 10px;
    color: #fff;
    height: 12px;
    line-height: 12px;
    border-radius: 15px;
    margin-left: 3px;
    margin-top: 2px;
}
.dic-list li .min{ background-color: #98C673; }
.dic-list li .max{ background-color: #D95340; }
.dic-list li .avg{ background-color: #ECBD61; }
.dic-list li .last{ background-color: #4A94D8; }

.header .title{
    font-family: Sansation;
    position: absolute;
    right: 65px;
    left: 65px;
    top: 0;
    height: 50px;
    text-align: center;
    font-size: 24px;
    line-height: 50px;
}
.header .title .s1{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 22px;
}
.header .title .s2{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 18px;
}
.header .title .s3{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
}

.grad{
    background: linear-gradient(to right, #089CFD, #6AEAFC);
    height: 40px;
    border-radius: 20px;
    margin: 5px 10px;
    position: relative;
    font-family: Sansation;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 45px;
    padding-right: 45px;
    text-overflow: ellipsis;
    text-align: center;
    color: #fff;
}
.grad .cross{
    position: absolute;
    right: 7px;
    top: 7px;
    /*border: solid 1px #f00;*/
    background-color: #fff;
    border-radius: 15px;
    width: 26px;
    height: 26px;
    background-image: url("../Images/cross.svg");
    background-repeat: no-repeat;
    background-position: center;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 600ms;
    -moz-transition: all 600ms;
    -o-transition: all 600ms;
    transition: all 600ms;
}

.grad .cross:active {
    background-color: #4A94D822;
}
.grad .ico{
    position: absolute;
    left: 9px;
    top: 7px;
    /*border: solid 1px #f00;*/
    width: 26px;
    height: 26px;
    background-image: url("../Images/meat.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.grad .cross:hover{
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.dic-info{
    padding: 0;
    margin: 0;
    margin-top: 25px;
    padding-left: 50px;
    padding-right: 15px;
}
.dic-info li{
    display: block;
    position: relative;
    font-size: 12px;
    line-height: 30px;
    color: #333333;
    font-weight: 100;
    font-family: HelveticaNeueM;
}
.dic-info li span{
    position: absolute;
    right: 10px;
    top: 3px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    padding: 0 10px;
}
.dic-info li span b{
    font-family: Times;
    font-weight: 500;
    font-size: 11px;
}
.dic-info li span.round{
    position: absolute;
    right: 10px;
    top: 3px;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
    border-radius: 10px;
    padding: 0 10px;
    color: #fff;
}
.buycount{
    margin-top: 20px;
}
.dic-info li span.round.min{ background-color: #98C673; }
.dic-info li span.round.max{ background-color: #D95340; }
.dic-info li span.round.avg{ background-color: #ECBD61; }
.dic-info li span.round.last{ background-color: #4A94D8; }

.dic-detail-container h2{
    display: block;
    position: relative;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    font-weight: 100;
    font-family: HelveticaNeueM;
    margin-left: 30px;
    margin-top: 40px;
}
.dic-history{
    padding: 10px 25px 10px 50px;
    margin: 0;
}
.dic-history li{
    display: flex;
    font-size: 10px;
    font-family: HelveticaNeueM;
    color: #333333;
    justify-content: space-between;
    height: 25px;
}
.dic-history li div:nth-child(1){
    width: 50%;
}
.dic-history li div:nth-child(2){
    width: 30%;
}
.dic-history li div:nth-child(3){
    text-align: right;
}

.Loader{
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom, #089CFD, #6AEAFC);
    font-family: Sansation;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}
.Loader .ver{
    position: fixed;
    bottom: 5px;
    font-size: 9px;
    font-family: Arial;
}
.Login{
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom, #089CFD, #6AEAFC);
    font-family: Sansation;
    color: #fff;
    text-align: center;
    justify-content: center;
    font-size: 32px;
    text-align: center;
}
.Login span{
    display: block;
    margin-top: 45%;
}
.Login input{
    border: none;
    display: block;
    width: 80%;
    margin:  0 auto;
    font-size: 20px;
    font-family: Sansation;
    padding: 10px;
    margin-top: 40px;
    background: #FAFAFA;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    x-color: #CFCFCF;
}
.Login input::-webkit-input-placeholder { /* Chrome */
    color: #CFCFCF;
}
.Login input:-ms-input-placeholder { /* IE 10+ */
    color: #CFCFCF;
}
.Login input::-moz-placeholder { /* Firefox 19+ */
    color: #CFCFCF;
    opacity: 1;
}
.Login input:-moz-placeholder { /* Firefox 4 - 18 */
    color: #CFCFCF;
    opacity: 1;
}
.Login .btn-ok{
    width: 60px;
    height: 60px;
    margin: 0 auto;
    margin-top: 40px;
    background-image: url("../Images/ok.svg");
    background-size: contain;
    cursor: pointer;
    color: #f00;
}

.cart-list{
    /*border: #f0f 1px solid;*/
    margin: 0;
    margin-top: 20px;
    padding: 10px 10px 10px 25px !important;
}
.cart-list li{
    display: block;
    font-family: Sansation;
    position: relative;
    height: 40px;
}
.cart-list li .total{
    position: absolute;
    right: 0;
    top: 0;
    font-size: 18px;
    font-family: Poppins;
    color: #747474;
    line-height: 40px;
}
.cart-list li .title{
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #98C673;
}
.cart-list li .sub-title{
    position: absolute;
    left: 0;
    top: 22px;
    line-height: 15px;
    color: #54BBFF;
    font-size: 10px;
}
.cart-list li .sub-title b{
    color: #939393;
    font-weight: normal;
    padding-left: 5px;
    padding-right: 3px;
}
.addItem{
    /*border: solid 1px #f00;*/
    height: 40px;
    background-image: url("../Images/plus-item.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-top: 25px;
}
.soTotal{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 52px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
}
.soTotal .title{
    font-family: Sansation;
    color: #4B4B4B;
    font-size: 22px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.soTotal .money{
    font-family: Poppins;
    color: #505050;
    font-size: 28px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 10px;
}
.soTotal .money b{
    font-family: Sansation;
    font-weight: 400;
    font-size: 25px;
}
.add-item{
    background: linear-gradient(to bottom, #089CFD, #6AEAFC);
    margin-top: -50px;
    min-height: calc(100% + 105px);
}
.item-cost-statistic{
    margin: 15px 30px;
    padding: 0;
    display: flex;
    justify-content: space-around;
}
.item-cost-statistic li{
    display: inline-flex;
    padding: 4px 2px;
    font-size: 10px;
    font-family: Sansation;
    font-weight: bold;
    color: #fff;
    opacity: 0.5;
    border-bottom: solid 2px #fff;
}


.statistic .title{
    font-family: Sansation;
    font-style: italic;
    font-weight: normal;
    line-height: normal;
    font-size: 18px;
    color: #FFFFFF;
    margin-left: 30px;
    margin-bottom: 10px;
}
.statistic ul{
    margin: 0 30px;
    padding: 0;
    display: flex;
    justify-content: space-around;
}
.statistic ul li{
    display: inline-flex;
    padding: 2px 20px;
    font-size: 12px;
    border-radius: 15px;
    font-family: Sansation;
    font-weight: bold;
    color: #fff;
}
.statistic ul li.min{ background-color: #98C673; }
.statistic ul li.max{ background-color: #D95340; }
.statistic ul li.avg{ background-color: #ECBD61; }
.statistic ul li.last{ background-color: #4A94D8; }

.add-item-container{
    border: none;
    display: block;
    width: 80%;
    margin:  0 auto;
    font-size: 20px;
    font-family: Sansation;
    padding: 10px;
    background: #FAFAFA;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    x-color: #CFCFCF;
}
.add-item-container input{
    border: none;
    background: none;
    margin:  0 auto;
    font-size: 20px;
    font-family: Sansation;
}
.add-item-container.pad-top{
    margin-top: 50px;
}
.add-item-header{
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    font-family: Sansation;
    color: #fff;
    padding: 0;
    margin: 0;
    position: relative;
}
.add-item-header .btn-back{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 10px;
    top: 10px;
    background: url("../Images/arrow_left.svg") center no-repeat;
    background-size: contain;
}
.input-title{
    font-family: Sansation;
    color: #fff;
    padding: 0;
    margin: 0;
    padding-left: 25px;
    margin-top: 10px;
}
.input-field{
    border: none;
    display: block;
    width: 80%;
    margin:  0 auto;
    font-size: 20px;
    font-family: Sansation;
    padding: 10px;
    margin-top: 4px;
    background: #FAFAFA;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
}
.input-field-short{
    border: none;
    display: inline-block;
    width: 50%;
    margin-left: 25px;
    font-size: 20px;
    font-family: Sansation;
    padding: 10px;
    margin-top: 4px;
    background: #FAFAFA;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
}
.input-units{
    /*border: solid 1px #f00;*/
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    width: calc(30% - 20px);
    padding-left: 20px;
    vertical-align: top;
    margin-top: 4px;
    font-family: Sansation;
    color: #fff;
}
.input-units ul{
    display: none;
}
.input-units .current:after{
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    background: url("../Images/triangle_dn.svg") center no-repeat;
    background-size: contain;
    margin-left: 15px;
}
.btn-ok{
    width: 60px;
    height: 60px;
    margin: 20px auto;
    background: url("../Images/ok.svg") center no-repeat;
    background-size: contain;
}
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
 -moz-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type="number"] {
    -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
    -moz-appearance: number-input;
}

.login-error{
    transition-duration: .7s;
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
     border-radius: 5px;
    padding: 10px;
    font-size: 15px;
    background-color: #FF004D;
    z-index: 888;
    color: #fff;
}
.dic-help{
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffffff;
    margin: 0 30px;
    margin-top: 1px;
    position: relative;
}
.dic-help::after{
    display: block;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 15px;
    bottom: -15px;
    /*background-color: #222;*/
    background-image: url("../Images/dd-bg4.png");
    background-size: contain;
}
.dic-help li{
    display: block;
    color: #222;
    padding: 3px;
    margin: 3px 0;
}
.login-error.pad-top{
    top: 45px;
}
.dicAdd{
}

.input-field:focus, input:focus{
    outline: none;
}

.input-field::-ms-expand {
    display: none;
}
.input-field {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
select.input-field{
    width: calc(80% + 20px);
}
.modal{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffffcc;
    z-index: 333;
    display: flex;
    justify-content: center;
    align-items: center;

}
.modal-frame{
    width: 80%;
    border-radius: 3px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}
.modal-frame div:nth-child(1){
    text-align: center;
    font-family: Sansation;
    font-size: 22px;
    padding-top: 5px;
    padding-bottom: 10px;
}
.modal-frame div:nth-child(3){
    text-align: center;
    font-family: Sansation;
    font-size: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
    vertical-align: top;
}
.modal-frame div:nth-child(3) span{
    vertical-align: top;
    line-height: 20px;
    padding-left: 10px;
}
.modal-frame input{
    border: solid 1px #089CFD;
    color: #089CFD;
    font-family: Sansation;
    font-size: 15px;
    width: calc(100% - 50px);
    margin-left: 25px;
    height: 30px;
    text-align: center;
    border-radius: 20px;
    background-color: #FAFAFA;
}
.sw{
    display: inline-block;
    width: 25px;
    height: 5px;
    background-image: url("../Images/sw-o.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.sw.active{
    background-image: url("../Images/sw-v.png");
}
.round-btn{
    display: inline-block;
    width: 100px;
    margin-left: calc(50% - 50px);
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 20px;
    margin-bottom: 10px;
    background-color: #2EBBFC;
    font-family: Sansation;
    text-transform: uppercase;
    color: #fff;
}
.trd{
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    /*border: solid 1px #f00;*/
    background-image: url("../Images/triangle-dn-blue.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: #0f0;
}
.title-help{
    /*border: solid 1px #f00;*/
    margin: 0;
    background-color: #fff;
    position: absolute;
    top: 49px;
    left: -20px;
    right: -20px;
    z-index: 422;
    box-shadow: 0 0 1px #ccc;
    padding: 10px 20px;
}
.title-help li{
    display: block;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
}
.title-help-bg{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 421;
    bottom: 0;
}
.hist-list{
    padding: 10px;
    margin: 0;
}
.hist-list li{
    display: block;
    height: 30px;
    border-radius: 20px;
    padding: 0 10px;
    line-height: 30px;
    border: solid 1px #DADADA;
    margin-bottom: 10px;
    position: relative;
    font-size: 14px;
    padding-right: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.hist-list li span{
    position: absolute;
    width: 100px;
    right: 10px;
    top: 0;
    font-size: 12px;
    color: #666;

}
.history-cart-list{
    position: relative;
    padding: 0 10px !important;
    margin: 0 30px;
}
.dic-s1-list{
    display: flex;
    padding: 3px 7px;
    justify-content: space-between;
    margin: 0;
    margin-top: 10px;
    font-family: HelveticaNeueCyr;
    color: #2EBBFC;
}
.dic-s1-list li{
    display: inline-block;
    border: solid 1px #DADADA;
    height: 30px;
    margin: 0 10px;
    padding: 3px 20px;
    line-height: 30px;
    border-radius: 20px;
    cursor: pointer;
}
.dic-s1-list li.active{
    background-color: #2EBBFC;
    border: solid 1px #2EBBFC;
    color: #fff;
}
.dic-sec-list{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    text-align: center;
}
.dic-sec-list li{
    display: inline-block;
    width: 70px;
    height: 70px;
    margin: 10px 5px;
    border: 1px solid #DADADA;
    border-radius: 15px;
    line-height: 120px;
    font-family: HelveticaNeueCyr;
    font-size: 12px;
    text-align: center;
    color: #222;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
}
.dic-sec-list li.active{
    border: 1px solid #2EBBFC;
    background-color: #2EBBFC;
    color: #fff;
}
.login-links{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    font-size: 10px;
    justify-content: space-around;
    padding: 0;
    text-align: center;
}
.login-links li{
    display: inline-block;
}
.reg span{
    margin-top: 50px !important;
}
.reg input{
    margin-top: 8px !important;
}




@keyframes animate {
from {
transform: rotate(0deg);
}
to {
transform: rotate(360deg);
}
}
